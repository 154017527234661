import React from 'react'

import{FaGithub,FaTwitter,FaLinkedinIn} from 'react-icons/fa';

const socialMedia = () => {
  return (
    <div className="app__social">
       <a href="https://www.linkedin.com/in/sagar-jaglan/" target="_blank" rel="noreferrer">
       <div>
        <FaLinkedinIn />
       </div>
       </a>
       <a href="https://github.com/imjaglan"  target="_blank" rel="noreferrer">
       <div>
        <FaGithub/>
       </div>
       </a>
       <a href="https://twitter.com/imjaglan"  target="_blank" rel="noreferrer">
       <div>
        <FaTwitter/>
       </div>
       </a>
    </div>
  )
}

export default socialMedia